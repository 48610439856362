import {useCallback, useSyncExternalStore} from 'react';

import type {IndexedDevices, MediaDeviceInfoLike} from '@pexip/media-control';
import {compareDevices, reverseDeviceId} from '@pexip/media-control';
import type {GeneratedMediaSignals, MediaSignals} from '@pexip/media';
import {UserMediaStatus} from '@pexip/media';

import {UserMediaContext} from '../contexts/userMedia';
import type {Subscribe} from '../types';
import {
    createChangeOfMediaDevicesSubscription,
    createChangeOfTrackSubscription,
} from '../utils/userMedia';

import {useAssertedContext} from './useAssertContext';
import {useDeviceStatusInfo} from './useDeviceStatusInfo';

export const useUserMediaContext = () => useAssertedContext(UserMediaContext);

interface SelectDeviceChangeHandlerParams {
    devices: IndexedDevices;
    currentInput: MediaDeviceInfoLike | undefined;
    requestMedia: (device: MediaDeviceInfoLike | undefined) => void;
}
export const useSelectDeviceChangeHandler = ({
    devices,
    currentInput,
    requestMedia,
}: SelectDeviceChangeHandlerParams) =>
    useCallback(
        (id: string) => {
            if (!id) {
                return requestMedia(undefined);
            }
            const selected = reverseDeviceId(id);
            const [selectedDevice] = devices.get(selected);
            if (
                currentInput &&
                selectedDevice &&
                compareDevices(currentInput)(selectedDevice)
            ) {
                return;
            }
            requestMedia(selectedDevice);
        },
        [currentInput, devices, requestMedia],
    );

interface MediaParams<K extends keyof MediaSignals> {
    signals: GeneratedMediaSignals<K>;
    muteAudio: (muted: boolean, persist?: boolean) => void;
    muteVideo: (muted: boolean, persist?: boolean) => void;
}

export const createUserMediaHooks = ({
    signals,
    muteAudio,
    muteVideo,
}: MediaParams<
    | 'onDevicesChanged'
    | 'onStatusChanged'
    | 'onMediaChanged'
    | 'onAudioMuteStateChanged'
    | 'onVideoMuteStateChanged'
    | 'onAddTrack'
    | 'onRemoveTrack'
>) => {
    const changeOfMediaDevices =
        createChangeOfMediaDevicesSubscription(signals);
    const changeOfAudioTrack = createChangeOfTrackSubscription(
        signals,
        'audio',
    );
    const changeOfVideoTrack = createChangeOfTrackSubscription(
        signals,
        'video',
    );

    const subscribeAudioMute: Subscribe = onChange => {
        const unsubscribes = [
            signals.onAudioMuteStateChanged.add(onChange),
            changeOfAudioTrack(onChange),
        ];

        return () => {
            for (const unsubscribe of unsubscribes) {
                unsubscribe();
            }
        };
    };

    const subscribeVideoMute: Subscribe = onChange => {
        const unsubscribes = [
            signals.onVideoMuteStateChanged.add(onChange),
            changeOfVideoTrack(onChange),
        ];

        return () => {
            for (const unsubscribe of unsubscribes) {
                unsubscribe();
            }
        };
    };

    const useAudioInput = (subscribe: Subscribe = changeOfAudioTrack) => {
        const userMedia = useUserMediaContext();
        return useSyncExternalStore(
            subscribe,
            () => userMedia.media?.audioInput,
        );
    };
    const useVideoInput = (subscribe: Subscribe = changeOfVideoTrack) => {
        const userMedia = useUserMediaContext();
        return useSyncExternalStore(
            subscribe,
            () => userMedia.media?.videoInput,
        );
    };

    const useDevices = (
        subscribe: Subscribe = signals.onDevicesChanged.add,
    ) => {
        const userMedia = useUserMediaContext();
        return useSyncExternalStore(subscribe, () => userMedia.devices);
    };

    const useMediaStream = (
        subscribe: Subscribe = signals.onMediaChanged.add,
    ) => {
        const userMedia = useUserMediaContext();
        return useSyncExternalStore(subscribe, () => userMedia.media?.stream);
    };

    const useMediaStatus = (
        subscribe: Subscribe = signals.onStatusChanged.add,
    ) => {
        const userMedia = useUserMediaContext();
        return useSyncExternalStore(
            subscribe,
            () => userMedia.media?.status ?? UserMediaStatus.Initial,
        );
    };

    const useAudioMuteState = (subscribe: Subscribe = subscribeAudioMute) => {
        const userMedia = useUserMediaContext();
        return useSyncExternalStore(
            subscribe,
            () => userMedia.media?.audioMuted,
        );
    };

    const useVideoMuteState = (subscribe: Subscribe = subscribeVideoMute) => {
        const userMedia = useUserMediaContext();
        return useSyncExternalStore(
            subscribe,
            () => userMedia.media?.videoMuted,
        );
    };

    const useSetAudioMuted = (
        muted = true,
        setState: (muted: boolean, persist?: boolean) => void = muteAudio,
        subscribe: Subscribe = subscribeAudioMute,
    ) => {
        const userMedia = useUserMediaContext();
        const mediaMuted = useAudioMuteState(subscribe);
        return (persist?: boolean) => {
            if (mediaMuted === undefined) {
                return;
            }
            setState(muted, persist);
            userMedia.media?.muteAudio(muted);
        };
    };

    const useToggleAudioMuted = (
        setState: (muted: boolean, persist?: boolean) => void = muteAudio,
        subscribe: Subscribe = subscribeAudioMute,
    ) => {
        const userMedia = useUserMediaContext();
        const muted = useAudioMuteState(subscribe);
        return (persist?: boolean) => {
            if (muted === undefined) {
                return;
            }
            setState(!muted, persist);
            userMedia.media?.muteAudio(!muted);
        };
    };

    const useToggleVideoMuted = (
        setState: (muted: boolean, persist?: boolean) => void = muteVideo,
        subscribe: Subscribe = subscribeVideoMute,
    ) => {
        const muted = useVideoMuteState(subscribe);
        const userMedia = useUserMediaContext();
        return (persist?: boolean) => {
            if (muted === undefined) {
                return;
            }
            setState(!muted, persist);
            userMedia.media?.muteVideo(!muted);
        };
    };

    const useHaveRequestedAudio = (
        subscribe: Subscribe = changeOfAudioTrack,
    ) => {
        const userMedia = useUserMediaContext();
        return useSyncExternalStore(
            subscribe,
            () => userMedia.media?.requestedAudio() ?? false,
        );
    };

    const useHaveRequestedVideo = (
        subscribe: Subscribe = changeOfVideoTrack,
    ) => {
        const userMedia = useUserMediaContext();
        return useSyncExternalStore(
            subscribe,
            () => userMedia.media?.requestedVideo() ?? false,
        );
    };

    const useSourceAudioTrack = (subscribe: Subscribe = changeOfAudioTrack) => {
        const userMedia = useUserMediaContext();
        const getState = () =>
            userMedia.media?.getAudioTracks().at(0)?.source.track;
        return useSyncExternalStore(subscribe, getState);
    };

    const useSourceVideoTrack = (subscribe: Subscribe = changeOfVideoTrack) => {
        const userMedia = useUserMediaContext();
        const getState = () =>
            userMedia.media?.getVideoTracks().at(0)?.source.track;
        return useSyncExternalStore(subscribe, getState);
    };

    const useAudioInputUnavailable = (
        subscribe: Subscribe = changeOfMediaDevices,
    ) => {
        const userMedia = useUserMediaContext();
        const getState = () =>
            userMedia.media?.isAudioInputUnavailable() ?? false;
        return useSyncExternalStore(subscribe, getState);
    };

    const useVideoInputUnavailable = (
        subscribe: Subscribe = changeOfMediaDevices,
    ) => {
        const userMedia = useUserMediaContext();
        const getState = () =>
            userMedia.media?.isVideoInputUnavailable() ?? false;
        return useSyncExternalStore(subscribe, getState);
    };

    const useHaveRequestedMedia = () => ({
        audio: useHaveRequestedAudio(),
        video: useHaveRequestedVideo(),
    });

    const useInputDeviceStatusInfo = () => {
        const status = useMediaStatus();
        const requested = useHaveRequestedMedia();
        return useDeviceStatusInfo(status, requested);
    };

    return {
        useDevices,
        useMediaStatus,
        useMediaStream,
        useAudioInput,
        useAudioMuteState,
        useVideoMuteState,
        useVideoInput,
        useToggleVideoMuted,
        useToggleAudioMuted,
        useHaveRequestedAudio,
        useHaveRequestedVideo,
        useHaveRequestedMedia,
        useSetAudioMuted,
        useSourceVideoTrack,
        useSourceAudioTrack,
        useAudioInputUnavailable,
        useVideoInputUnavailable,
        useInputDeviceStatusInfo,
    };
};
