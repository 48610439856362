import React from 'react';
import cx from 'classnames';

import type {ColorScheme} from '../../../types/variants';
import {ThemeConsumer} from '../../../themes/ThemeContext';

import styles from './ProgressBar.module.scss';

export enum ProgressBarColor {
    Good = 'good',
    Warn = 'warn',
    Danger = 'danger',
    Primary = 'primary',
}

export const ProgressBar: React.FC<
    React.ComponentProps<'div'> & {
        backgroundClassName?: string;
        barClassName?: string;
        colorScheme?: ColorScheme;
        progress: number;
        barColor?: ProgressBarColor;
    }
> = ({
    backgroundClassName,
    barClassName,
    barColor = ProgressBarColor.Good,
    colorScheme,
    progress,
    style,
    ...props
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => (
            <div
                className={cx(
                    styles.wrap,
                    styles[colorScheme ?? defaultColorScheme],
                    backgroundClassName,
                )}
                {...props}
            >
                <div
                    className={cx(
                        styles.bar,
                        styles[barColor] ?? styles.good,
                        barClassName,
                    )}
                    style={{
                        transform: `translateX(${
                            progress > 100 ? 100 : Math.round(progress)
                        }%)`,
                        ...style,
                    }}
                />
            </div>
        )}
    </ThemeConsumer>
);

export type ProgressBarProps = React.ComponentProps<typeof ProgressBar>;
