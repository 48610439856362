import React, {
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import cx from 'classnames';

import {useOnClickOutside} from '@pexip/hooks';

import type {
    ExtendedSizeModifier,
    ModalSizeModifier,
} from '../../../types/sizes';
import type {BoxProps} from '../../elements/Box/Box';
import {sizeToPadding} from '../../../utils/helpers';
import {TestId} from '../../../utils/testIds';
import {Portal} from '../../elements/Portal/Portal';
import {useFocusTrap, initializeFocus} from '../../modules/Focus/useFocusTrap';
import {ModalCloseButton} from '../CloseButton/ModalCloseButton.view';
import {Box} from '../../elements/Box/Box';
import type {ColorScheme} from '../../../types/variants';
import {ThemeContext, ThemeProvider} from '../../../themes/ThemeContext';
import {Scrollbars} from '../../elements/Scrollbars/Scrollbars';
import {Draggable} from '../../elements/Draggable/Draggable.view';
import {useFocusFrom} from '../../../hooks/useFocusFrom';

import styles from './Modal.module.scss';

export enum ModalType {
    Overlay = 'overlay',
    Positioned = 'positioned',
}

const Div: React.FC<React.ComponentProps<'div'>> = ({children, ...props}) => (
    <div {...props}>{children}</div>
);

export interface ModalHandle {
    focus: () => void;
}

export const Modal = React.forwardRef<
    ModalHandle,
    React.ComponentProps<'div'> & {
        canDrag?: boolean;
        closeButtonAriaLabel?: string;
        closeOnOutsideClick?: boolean;
        colorScheme?: ColorScheme;
        hasBackground?: boolean;
        isOpen: boolean;
        modalType?: ModalType;
        onClose?: () => void;
        padding?: ExtendedSizeModifier;
        scrollbarsAriaLabel?: string;
        sizeModifier?: ModalSizeModifier;
        testIdCloseButton?: string;
        uniqueTitle?: string;
        withCloseButton?: boolean;
        withScrollbars?: boolean;
        wrapperClassName?: string;
        withMask?: boolean;
    } & Pick<
            BoxProps,
            | 'footerContent'
            | 'footerPadding'
            | 'hasFooterShadow'
            | 'headerContent'
            | 'headerPadding'
        >
>(
    (
        {
            canDrag = false,
            children,
            className,
            closeButtonAriaLabel = 'Close tooltip',
            closeOnOutsideClick = true,
            colorScheme = 'light',
            footerContent,
            footerPadding,
            hasBackground,
            hasFooterShadow,
            headerContent,
            headerPadding,
            isOpen = false,
            modalType = ModalType.Overlay,
            onClose,
            padding = 'medium',
            scrollbarsAriaLabel,
            sizeModifier = 'small',
            testIdCloseButton = TestId.ButtonModalClose,
            uniqueTitle,
            withCloseButton = false,
            withScrollbars = true,
            wrapperClassName,
            withMask = true,
            style,
            ...props
        },
        ref,
    ) => {
        const tryToClose = () => {
            if (closeOnOutsideClick) {
                onClose?.();
            }
        };
        const outsideClickRef = useOnClickOutside(tryToClose);
        const {focusTrapRef, setFocusTrapRef} = useFocusTrap(isOpen);
        const floatRef = React.useRef<HTMLDivElement>(null);
        const [positionedModalElement, setPositionedModalElement] =
            useState<HTMLDivElement | null>(null);

        const {focusFrom: focusPositionedModal} = useFocusFrom(
            positionedModalElement,
        );

        const Container = withScrollbars ? Scrollbars : Div;

        useImperativeHandle(ref, () => ({
            focus: () => {
                if (focusTrapRef.current instanceof HTMLElement) {
                    initializeFocus(focusTrapRef.current);
                } else if (positionedModalElement) {
                    focusPositionedModal();
                }
            },
        }));

        useEffect(() => {
            const closeOnEscape = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    onClose?.();
                }
            };
            isOpen
                ? document.addEventListener('keydown', closeOnEscape)
                : document.removeEventListener('keydown', closeOnEscape);
            return () => document.removeEventListener('keydown', closeOnEscape);
        }, [isOpen, onClose]);

        const {colorScheme: defaultColorScheme} = useContext(ThemeContext);

        let modal = (
            <ThemeProvider colorScheme={colorScheme ?? defaultColorScheme}>
                <Box
                    className={cx(
                        styles.modal,
                        styles[modalType],
                        sizeModifier !== 'none' && styles[sizeModifier],
                        !canDrag && className,
                    )}
                    ref={
                        modalType === ModalType.Positioned
                            ? (element: HTMLDivElement | null) => {
                                  setPositionedModalElement(element);
                              }
                            : setFocusTrapRef
                    }
                    hasBackground={hasBackground}
                    headerContent={headerContent}
                    footerContent={footerContent}
                    headerPadding={headerPadding}
                    footerPadding={footerPadding}
                    hasFooterShadow={hasFooterShadow}
                    {...(!canDrag && {style})}
                >
                    <Container
                        className={styles.scrollbars}
                        {...(withScrollbars
                            ? {ariaLabel: scrollbarsAriaLabel}
                            : {'aria-label': scrollbarsAriaLabel})}
                    >
                        <div
                            role="presentation"
                            ref={outsideClickRef}
                            className={styles[sizeToPadding(padding)]}
                        >
                            <div
                                role="dialog"
                                aria-modal="true"
                                aria-label={uniqueTitle}
                            >
                                {withCloseButton && (
                                    <ModalCloseButton
                                        className={styles.closeButton}
                                        onClose={onClose}
                                        data-testid={testIdCloseButton}
                                        aria-label={closeButtonAriaLabel}
                                    />
                                )}
                                {children}
                            </div>
                        </div>
                    </Container>
                </Box>
            </ThemeProvider>
        );

        if (canDrag) {
            modal = (
                <Draggable
                    className={className}
                    style={style}
                    floatRoot={floatRef}
                >
                    {modal}
                </Draggable>
            );
        }

        if (withMask) {
            modal = (
                <div
                    role="presentation"
                    className={cx(styles.modalMask, wrapperClassName)}
                    data-testid={TestId.ModalMask}
                >
                    {modal}
                </div>
            );
        }

        if (!isOpen) {
            return null;
        }

        return (
            <Portal>
                <div
                    className={styles.modalWrapper}
                    role="region"
                    ref={floatRef}
                    {...props}
                >
                    {modal}
                </div>
            </Portal>
        );
    },
);

Modal.displayName = 'Modal';

export type ModalProps = React.ComponentProps<typeof Modal>;
