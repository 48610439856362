import type {Signal} from '@pexip/signal';

export interface RTCStats {
    id: string;
    type: string;

    kind?: 'audio' | 'video';
    timestamp?: number;

    [key: string]: unknown;
}

export interface Remote extends RTCStats {
    type: 'remote-inbound-rtp';

    jitter?: number;

    packetsLost?: number;
    roundTripTime?: number;

    codec?: Codec;
    // local:
    transport?: Transport;
}

export interface CandidatePair extends RTCStats {
    type: 'candidate-pair';

    availableIncomingBitrate?: number;
    availableOutgoingBitrate?: number;
    currentRoundTripTime?: number;

    transport?: Transport;
    localCandidate?: CandidatePair;
    remoteCandidate?: CandidatePair;
}

export interface Transport extends RTCStats {
    type: 'transport';

    // localCertificate:
    // remoteCertificate:
    selectedCandidatePair?: CandidatePair;
}

export interface Codec extends RTCStats {
    type: 'codec';

    mimeType?: string;
}

export interface InboundAudio extends RTCStats {
    type: 'inbound-rtp';
    kind: 'audio';

    jitter?: number;

    packetsReceived?: number;
    packetsLost?: number;

    bytesReceived?: number;

    track?: Track;
    transport?: Transport;
    codec?: Codec;
}

export interface OutboundAudio extends RTCStats {
    type: 'outbound-rtp';
    kind: 'audio';

    packetsSent?: number;
    bytesSent?: number;

    remote?: Remote;
    transport?: Transport;
    codec?: Codec;
}

export interface InboundVideo extends RTCStats {
    type: 'inbound-rtp';
    kind: 'video';

    packetsReceived?: number;
    packetsLost?: number;

    bytesReceived?: number;

    transport?: Transport;
    codec?: Codec;

    frameWidth?: number;
    frameHeight?: number;
    framesPerSecond?: number;

    // typically firefox has these, but chrome does not:
    bitrateMean?: number;
    framerateMean?: number;
}

export interface OutboundVideo extends RTCStats {
    type: 'outbound-rtp';
    kind: 'video';

    packetsSent?: number;
    packetsLost?: number;

    bytesSent?: number;

    totalPacketSendDelay?: number;

    frameWidth?: number;
    frameHeight?: number;
    framesPerSecond?: number;

    codec?: Codec;
    mediaSource?: MediaSource;
    remote?: Remote;
    track?: Track;
    transport?: Transport;

    // typically firefox has these, but chrome does not:
    bitrateMean?: number;
    framerateMean?: number;
}

export interface MediaSource extends RTCStats {
    type: 'media-source';
    kind: 'audio' | 'video';
}

export interface Track extends RTCStats {
    type: 'track';
    kind: 'audio' | 'video';

    mediaSource?: MediaSource;
}

export interface Stream extends RTCStats {
    type: 'stream';

    tracks?: Track[];
}

export type AnyStats =
    | CandidatePair
    | Codec
    | InboundAudio
    | InboundVideo
    | OutboundAudio
    | OutboundVideo
    | RTCStats
    | Remote
    | Stream
    | Track
    | Transport;

export interface Metrics {
    type: 'inbound-rtp' | 'outbound-rtp';
    kind: 'audio' | 'video';
    bitrate?: number;
    bytesTransmitted?: number;
    codec?: string;
    jitter?: number;
    packetsLost: number;
    packetsTransmitted: number;
    recentPercentageLost?: number;
    roundTripTime?: number;
    timestamp?: number;
    totalPercentageLost?: number;
}

export interface VideoMetrics extends Metrics {
    framesPerSecond?: number;
    resolution?: string;
    resolutionHeight?: number;
    resolutionWidth?: number;
}

export type InboundAudioMetrics = Metrics;
export type InboundVideoMetrics = VideoMetrics;
export type OutboundAudioMetrics = Metrics;
export type OutboundVideoMetrics = VideoMetrics & {
    totalPacketSendDelay?: number;
    averagePacketSendDelay?: number;
};

export type NormalizedRTCStats =
    | InboundAudioMetrics
    | InboundVideoMetrics
    // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents --- keep it for consistency
    | OutboundAudioMetrics
    | OutboundVideoMetrics;

export type PacketsStats = [number, number][]; //packet loss, total packets
export type AudioQualityStats = [number, number][]; //tuple with packet loss and jitter
export type VideoQualityStats = number[]; //packet loss

export type CallQualityStats = AudioQualityStats | VideoQualityStats;
export type CallPacketsStats = PacketsStats;

export interface CacheStats {
    previous?: NormalizedRTCStats;
    callPacketsStats: CallPacketsStats;
    callQuality: Quality;
    callQualityStats: CallQualityStats;
}

export enum Quality {
    GOOD = 0,
    OK = 1,
    BAD = 2,
    TERRIBLE = 3,
}

export interface StatsSignals {
    onRtcStats: Signal<NormalizedRTCStats>;
    onCallQuality: Signal<Quality>;
    onCallQualityStats: Signal<CallQualityStats>;
}
export interface StatsCollectorOptions {
    input: {
        getStats: (
            selector?: MediaStreamTrack | null,
        ) => Promise<RTCStatsReport>;
    };
    signals: StatsSignals;
    interval?: number;
}

export interface StatsCollector {
    resetStats: () => () => void;
    cleanup: () => void;
}
