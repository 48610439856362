import type {
    MediaDeviceInfoLike,
    DeviceOrTrack,
    MediaStreamTrackLike,
} from './types';
import {MediaDeviceFailure} from './types';
import {toKey} from './devices';
import {isMediaStreamTrack} from './typeGuards';

type StreamTrackKey = string;

export const createStreamTrackMap = (tracks?: MediaStreamTrackLike[]) => {
    const streamTrackMap = new Map<StreamTrackKey, MediaStreamTrackLike>(
        tracks?.map(track => [toKey(track), track]),
    );

    /**
     * Find track from the list of track in use.
     */
    const findTrack = (
        device: MediaDeviceInfoLike,
    ): MediaStreamTrackLike | undefined => {
        return [...streamTrackMap]
            .map(([_, track]) => track)
            .find(track => {
                const {kind} = track;
                const {deviceId} = track.getSettings();
                if (!deviceId) {
                    return false;
                }
                return (
                    device.kind.includes(kind) && device.deviceId === deviceId
                );
            });
    };

    /**
     * Construct a `StreamTrackKey` from `DeviceOrTrack`
     */
    const toStreamTrackKey = (deviceOrTrack: DeviceOrTrack): StreamTrackKey => {
        // Unify interfaces
        const track = isMediaStreamTrack(deviceOrTrack)
            ? deviceOrTrack
            : findTrack(deviceOrTrack as MediaDeviceInfoLike);
        if (!track?.kind || !track.id) {
            return '';
        }
        return toKey(track);
    };

    /**
     * Add the `MediaStreamTrack` to `streamTrackMap`
     */
    const add = (track: MediaStreamTrackLike) => {
        const key = toStreamTrackKey(track);
        if (key) {
            return streamTrackMap.set(key, track);
        }
        throw new Error(MediaDeviceFailure.StreamTrackNotFound);
    };

    /**
     * Check if `streamTrackMap` has provided track or device
     */
    const has = (deviceOrTrack: DeviceOrTrack) => {
        const key = toStreamTrackKey(deviceOrTrack);
        return key && streamTrackMap.has(key);
    };

    /**
     * Stop all tracks and empty `streamTrackMap`
     */
    const clear = () => {
        for (const track of streamTrackMap.values()) {
            track.stop();
        }
        streamTrackMap.clear();
    };

    /**
     * Stop the track and remove the track from `streamTrackMap`
     */
    const remove = (track: MediaStreamTrackLike) => {
        const key = toStreamTrackKey(track);
        track.stop();
        return streamTrackMap.delete(key);
    };

    /**
     * Get the size of `streamTrackMap`
     */
    const size = () => streamTrackMap.size;

    return {
        add,
        has,
        clear,
        remove,
        size,
    };
};

export type StreamTrackMap = ReturnType<typeof createStreamTrackMap>;
