import type {MediaQuery} from '../types/aliases';
import type {Breakpoint} from '../../design-tokens/generated/breakpoints';
import {
    breakpoints,
    breakpointNames,
} from '../../design-tokens/generated/breakpoints';

export const breakpointNext = (name: Breakpoint): Breakpoint | null => {
    const index = breakpointNames.indexOf(name);
    if (index === -1) {
        throw new Error(`breakpoint ${name} not found in ${breakpointNames}`);
    }
    return breakpointNames[index + 1] ?? null;
};

export const breakpointMin = (name: Breakpoint): number | null => {
    const min = breakpoints[name];
    return min > 0 ? min : null;
};

/*
 * The maximum value is reduced by 0.02px to work around the limitations of
 * `min-` and `max-` prefixes and viewports with fractional widths.
 * See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
 * Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
 * See https://bugs.webkit.org/show_bug.cgi?id=178261
 */
export const breakpointMax = (name: Breakpoint | null): number | null => {
    if (name === null) {
        return null;
    }
    const reduceBy = 0.02;
    const value = breakpoints[name];
    return value > 0 ? value - reduceBy : null;
};

export const mediaBreakpointUp = (name: Breakpoint): MediaQuery => {
    const min = breakpointMin(name);
    if (min) {
        return `(min-width: ${min}px)`;
    }
    return '';
};

export const mediaBreakpointDown = (name: Breakpoint | null) => {
    const max = breakpointMax(name);
    if (max) {
        return `(max-width: ${max}px)`;
    }
    return '';
};

export const mediaBreakpointBetween = (
    lowerName: Breakpoint,
    upperName: Breakpoint,
): MediaQuery => {
    const min = breakpointMin(lowerName);
    const max = breakpointMax(upperName);

    if (min !== null && max !== null) {
        return `(min-width: ${min}px) and (max-width: ${max}px)`;
    }
    if (max === null) {
        return mediaBreakpointUp(lowerName);
    }
    return mediaBreakpointDown(upperName);
};

export const mediaBreakpointOnly = (name: Breakpoint): MediaQuery => {
    const min = breakpointMin(name);
    const next = breakpointNext(name);
    const max = breakpointMax(next);

    if (min !== null && max !== null) {
        return `(min-width: ${min}px) and (max-width: ${max}px)`;
    }
    if (max === null) {
        return mediaBreakpointUp(name);
    }
    return mediaBreakpointDown(next);
};
