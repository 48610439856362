import type {Breakpoint} from '../../design-tokens/generated/breakpoints';
import {breakpointNames} from '../../design-tokens/generated/breakpoints';
import type {
    BackgroundImageSrc,
    BreakpointImageSet,
    ImageSrc,
    SrcSet,
} from '../types/images';

export const isImageSrcString = (
    imageSrc: ImageSrc | undefined,
): imageSrc is string => typeof imageSrc === 'string';

export const isImageSrcSrcSet = (
    imageSrc: ImageSrc | undefined,
): imageSrc is SrcSet => typeof imageSrc === 'object';

export const isImageSrcEmpty = (imageSrc: ImageSrc | undefined) => {
    if (imageSrc === undefined) {
        return true;
    }
    if (isImageSrcString(imageSrc)) {
        return !imageSrc.trim();
    }

    const entries = Object.entries(imageSrc);
    return entries.every(([_, imageUrl]) => !imageUrl.trim());
};

export const convertSrcSetToString = (imageSrc: SrcSet) => {
    let srcSetAsString = '';
    const setDivider = ',';
    const entries = Object.entries(imageSrc);
    for (const entry of entries) {
        const xDescriptor = entry[0];
        const fileName = entry[1];
        srcSetAsString = srcSetAsString.concat(`${fileName} ${xDescriptor}`);
        if (entries.indexOf(entry) !== entries.length - 1) {
            srcSetAsString = srcSetAsString.concat(`${setDivider} `);
        }
    }
    return srcSetAsString.trim();
};

export const isBackgroundImageSrcUrl = (
    backgroundImageSrc: BackgroundImageSrc | undefined,
): backgroundImageSrc is string => typeof backgroundImageSrc === 'string';

export const isBackgroundImageSrcBreakpointImageSet = (
    backgroundImageSrc: BackgroundImageSrc | undefined,
): backgroundImageSrc is BreakpointImageSet =>
    typeof backgroundImageSrc === 'object';

export const isBackgroundImageSrcEmpty = (
    backgroundImageSrc: BackgroundImageSrc | undefined,
) => {
    if (backgroundImageSrc === undefined) {
        return true;
    }
    if (isBackgroundImageSrcUrl(backgroundImageSrc)) {
        return !backgroundImageSrc.trim();
    }

    const entries = Object.entries(backgroundImageSrc);
    return entries.every(([_, imageUrl]) => !imageUrl.trim());
};

export const getSmallToLargeSrcSetEntries = (srcSet: SrcSet) => {
    const srcSetEntries = Object.entries(srcSet);
    const smallToLargeSrcSetEntries = srcSetEntries.sort(
        ([widthUnitA], [widthUnitB]) =>
            Number(widthUnitA.replace('x', '')) -
            Number(widthUnitB.replace('x', '')),
    );
    return smallToLargeSrcSetEntries;
};

export const getHighestResolutionImageUrlFromSrcSet = (
    srcSet: SrcSet,
): string | null => {
    const smallToLargeSrcSetEntries = getSmallToLargeSrcSetEntries(srcSet);
    const imageUrl =
        smallToLargeSrcSetEntries[smallToLargeSrcSetEntries.length - 1]?.[1];
    return imageUrl || null;
};

export const getHighestResolutionImageUrlFromBreakpointImageSet = (
    breakpointImageSet: BreakpointImageSet,
): string | null => {
    const largeToSmallBreakpointNames = [...breakpointNames].reverse();
    for (const breakpointName of largeToSmallBreakpointNames) {
        const imageUrl = breakpointImageSet[breakpointName];
        if (imageUrl) {
            return imageUrl;
        }
    }
    return null;
};

export const getFilledBreakpointImageSet = (
    breakpointImageSet: BreakpointImageSet,
) => {
    const highestResolutionImageUrl =
        getHighestResolutionImageUrlFromBreakpointImageSet(breakpointImageSet);
    if (highestResolutionImageUrl) {
        const largeToSmallBreakpointNames = [...breakpointNames].reverse();
        const breakpointImageSetCopy = {...breakpointImageSet};
        largeToSmallBreakpointNames.forEach((breakpointName, i) => {
            if (!breakpointImageSetCopy[breakpointName]) {
                const oneBreakpointUp = largeToSmallBreakpointNames[i - 1];
                const oneBreakpointImageUrl =
                    oneBreakpointUp && breakpointImageSetCopy[oneBreakpointUp];
                const imageUrl =
                    oneBreakpointImageUrl || highestResolutionImageUrl;
                breakpointImageSetCopy[breakpointName] = imageUrl;
            }
        });
        return breakpointImageSetCopy;
    }
    return breakpointImageSet;
};

type BreakpointBackgroundImageVariable = `--${Breakpoint}-background-image`;

export const getBackgroundImageCSSVariablesFromBreakpointImageSet = (
    breakpointImageSet: BreakpointImageSet,
): Partial<Record<BreakpointBackgroundImageVariable, string>> => {
    const filledBreakpointImageSet =
        getFilledBreakpointImageSet(breakpointImageSet);
    const cssVariables = Object.fromEntries(
        Object.entries(filledBreakpointImageSet).map(
            ([breakpointName, imageUrl]) => {
                const cssVariable: BreakpointBackgroundImageVariable = `--${
                    breakpointName as Breakpoint
                }-background-image`;
                return [cssVariable, `url("${imageUrl}")`];
            },
        ),
    );
    return cssVariables;
};
