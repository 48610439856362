import React, {useState} from 'react';
import type {SyntheticEvent} from 'react';

import styles from './ContextMenu.module.scss';

/**
 * ContextMenu will the show the "menu" component as a context menu when the
 * user right clicks on the given child element.
 *
 * Right or left-clicking outside the child element will close the context
 * menu.
 */
export const ContextMenu: React.FC<{
    children: React.ReactElement;
    menu:
        | React.ReactElement
        | ((props: {onClose: () => void}) => React.ReactElement);
}> = ({children, menu}) => {
    const [showMenu, setShowMenu] = useState(false);

    const closeMenu = () => setShowMenu(false);

    const onContextMenu = (e: SyntheticEvent) => {
        setShowMenu(!showMenu);
        if (!showMenu) {
            // if we're not showing the menu then we want right click to only open
            // up our menu, not the default context menu
            e.preventDefault();
        }
    };

    const menuEntry =
        typeof menu === 'function' ? menu({onClose: closeMenu}) : menu;

    return (
        <div
            onContextMenu={onContextMenu}
            role="presentation"
            tabIndex={-1}
            className={styles.wrapper}
        >
            {showMenu && (
                <>
                    {/* biome-ignore lint/a11y/useKeyWithClickEvents: tabIndex is -1, so not focusable via keyboard */}
                    <div
                        className={styles.clickCatcher}
                        onClick={closeMenu}
                        role="presentation"
                        tabIndex={-1}
                    />
                    <div className={styles.positionAdjuster}>{menuEntry}</div>
                </>
            )}
            {children}
        </div>
    );
};
