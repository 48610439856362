/**
 * Log meta and message with respective log level
 */
export type LogMethod = (meta: unknown, message?: string) => void;

export enum LogLevels {
    trace = 10,
    debug = 20,
    info = 30,
    warn = 40,
    error = 50,
    fatal = 60,
    silent = 2 ** 53 - 1, // Number.MAX_SAFE_INTEGER,
}

export type LogLevelsString = keyof typeof LogLevels;

export type LogMethods = {
    [key in LogLevelsString]: LogMethod;
};

/**
 * Log Level from high to low, "fatal" | "error" | "warn" | "info" | "debug" | "trace"
 * Typically, debug and trace logs are only valid for development, and not needed in production
 */
export interface Logger extends LogMethods {
    /**
     * Adds a value to the redaction set, which makes it replaced by [REDACTED] when logged to file.
     *
     * @remarks
     * The redaction set is applied globally, and only applies to the log file, not console logs.
     *
     * @param value - the string to redact
     */
    redact(value: string): void;
}

/**
 * Create a logger with console API, and map fatal to error, skipping trace
 * and silent, and there is no redaction
 */
export function createConsoleLogger() {
    return Object.freeze<Logger>({
        fatal: (meta, message) => console.error(message, meta),
        error: (meta, message) => console.error(message, meta),
        warn: (meta, message) => console.warn(message, meta),
        info: (meta, message) => console.info(message, meta),
        debug: (meta, message) => console.debug(message, meta),
        trace() {
            // Noop
        },
        silent() {
            // Noop
        },
        redact() {
            // Noop
        },
    });
}
