import React, {useEffect, useRef, useState} from 'react';

import type {AudioQualityStats} from '@pexip/peer-connection-stats';
import {STATS_SIZE, getQuality, Quality} from '@pexip/peer-connection-stats';
import {Cell, Grid, IconTypes, List, Color} from '@pexip/components';
import type {Signal} from '@pexip/signal';

import {
    CallQualityVisualizer,
    QualityNames,
} from '../views/CallQualityVisualizer/CallQualityVisualizer.view';

const WIDTH = 170;
const HEIGHT = 18;
const ITEMS_SIZE = STATS_SIZE;
const GRAPH_COLOR = Color.DeepBlue90;

export const AVQualityStats = ({
    callQualityStatsSignal,
}: {
    callQualityStatsSignal: Signal<{
        inbound: {audio: AudioQualityStats};
        outbound: {audio: AudioQualityStats};
    }>;
}) => {
    const canvasInRef = useRef<HTMLCanvasElement>(null);
    const canvasOutRef = useRef<HTMLCanvasElement>(null);

    const [qualityIn, setQualityIn] = useState({
        name: QualityNames.GOOD,
        value: 1,
    });

    const [qualityOut, setQualityOut] = useState({
        name: QualityNames.GOOD,
        value: 1,
    });

    useEffect(
        () =>
            callQualityStatsSignal.add(stats => {
                if (canvasInRef.current && stats?.inbound?.audio.length > 0) {
                    setQualityIn(
                        updateQuality(stats.inbound.audio, canvasInRef.current),
                    );
                }
                if (canvasOutRef.current && stats?.outbound?.audio.length > 0) {
                    setQualityOut(
                        updateQuality(
                            stats.outbound.audio,
                            canvasOutRef.current,
                        ),
                    );
                }
            }),
        [callQualityStatsSignal],
    );

    return (
        <List>
            <Grid>
                <Cell xs={6}>
                    <CallQualityVisualizer
                        ref={canvasInRef}
                        width={WIDTH}
                        height={HEIGHT}
                        quality={qualityIn}
                        icon={IconTypes.IconArrowDown}
                    />
                </Cell>
                <Cell xs={6}>
                    <CallQualityVisualizer
                        ref={canvasOutRef}
                        width={WIDTH}
                        height={HEIGHT}
                        quality={qualityOut}
                        icon={IconTypes.IconArrowUp}
                    />
                </Cell>
            </Grid>
        </List>
    );
};

function updateQuality(stats: AudioQualityStats, canvas: HTMLCanvasElement) {
    const {goodOrOkQuality, quality, qualityOverTime} = getQuality(stats);
    drawGraph(canvas, qualityOverTime);

    return {
        value: goodOrOkQuality,
        name: calculateQualityName(quality),
    };
}

function drawGraph(
    canvas: HTMLCanvasElement,
    callQuality: Quality[],
    itemSize = ITEMS_SIZE,
) {
    const context = canvas.getContext('2d');
    if (!context) {
        return;
    }

    context.clearRect(0, 0, canvas.width, canvas.height);

    context.beginPath();

    context.lineJoin = 'round';
    context.strokeStyle = GRAPH_COLOR;

    context.moveTo(
        canvas.width,
        calculateGraphItemValue(callQuality[0]) * canvas.height,
    );
    callQuality.forEach((quality, i) => {
        if (i === 0) {
            return;
        }
        context.lineTo(
            canvas.width - (i * canvas.width) / itemSize,
            calculateGraphItemValue(quality) * canvas.height,
        );
    });

    context.stroke();
}

function calculateGraphItemValue(quality?: Quality) {
    switch (quality) {
        case Quality.GOOD:
            return 0;
        case Quality.OK:
            return 0.33;
        case Quality.BAD:
            return 0.66;
        default:
            return 1;
    }
}

export function calculateQualityName(quality?: Quality) {
    switch (quality) {
        case Quality.TERRIBLE:
            return QualityNames.TERRIBLE;
        case Quality.BAD:
            return QualityNames.BAD;
        case Quality.OK:
            return QualityNames.OK;
        default:
            return QualityNames.GOOD;
    }
}
