import React from 'react';

export const convertNewLinesToBr = (
    text: string,
    textId: number,
): React.ReactNode => {
    if (!text.includes('\n')) {
        return text;
    }
    return text.split('\n').map(textLine => (
        <React.Fragment key={`${textLine}:${textId}`}>
            {textLine}
            <br />
        </React.Fragment>
    ));
};
