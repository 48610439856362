import {camelCase} from 'change-case';

import type {ExtendedSizeModifier} from '../types/sizes';

export const sizeToPadding = (t: ExtendedSizeModifier) =>
    camelCase(`${t} padding`) as
        | 'compactPadding'
        | 'largePadding'
        | 'mediumPadding'
        | 'nonePadding'
        | 'smallPadding';

export const sizeToSpacing = (t: ExtendedSizeModifier) =>
    camelCase(`${t} spacing`) as
        | 'compactSpacing'
        | 'largeSpacing'
        | 'mediumSpacing'
        | 'noneSpacing'
        | 'smallSpacing';

export const removeKeyFromObject = (
    key: string,
    object: Record<string, unknown>,
): Record<string, unknown> => {
    return Object.keys(object).reduce(
        (result, current) => {
            if (current !== key) {
                result[current] = object[current];
            }

            return result;
        },
        {} as Record<string, unknown>,
    );
};
