import type {
    MediaDeviceRequest,
    StreamTrackEventHandlers,
    Unsubscribe,
} from './types';
import {isBoolean} from './typeGuards';
import type {Dispatch} from './eventEmitter';
import {MediaEventType} from './eventEmitter';
import {getMediaStream} from './getMediaStream';
import type {StreamTrackMap} from './streamTrackMap';

/**
 * Handle media streaming related functions and events
 *
 * @returns getUserMedia function to stream media
 *
 * @internal
 */
export const handleMediaStream = ({
    dispatch,
    getDefaultConstraints,
    streamTrackMap,
}: {
    dispatch: Dispatch;
    getDefaultConstraints: () => MediaStreamConstraints;
    streamTrackMap: StreamTrackMap;
}) => {
    const setupMediaStreamTracks = ({stream}: {stream: MediaStream}) => {
        let userFacingMode = false;

        for (const track of stream.getTracks()) {
            const {facingMode} = track.getSettings();

            if (track) {
                streamTrackMap.add(track);
            }

            if (track.kind === 'video' && isBoolean(facingMode)) {
                userFacingMode = facingMode;
            }

            const mutedListener = () => {
                dispatch({id: track.id, type: MediaEventType.Mute});
            };
            track.addEventListener(MediaEventType.Mute, mutedListener, false);

            const unmutedListener = () => {
                dispatch({id: track.id, type: MediaEventType.Unmute});
            };
            track.addEventListener(
                MediaEventType.Unmute,
                unmutedListener,
                false,
            );

            const endedListener = () => {
                dispatch({id: track.id, type: MediaEventType.Ended});
                track.removeEventListener(
                    MediaEventType.Ended,
                    endedListener,
                    false,
                );
                track.removeEventListener(
                    MediaEventType.Mute,
                    mutedListener,
                    false,
                );
                track.removeEventListener(
                    MediaEventType.Unmute,
                    unmutedListener,
                    false,
                );
                if (streamTrackMap.has(track)) {
                    streamTrackMap.remove(track);
                }
            };
            track.addEventListener('ended', endedListener, true);
        }

        dispatch({
            facingMode: userFacingMode,
            stream,
            type: MediaEventType.Stream,
            video: stream.getVideoTracks().length > 0,
        });
    };

    /**
     * Get MediaStream with MediaDeviceRequest
     *
     * @returns MediaStream
     *
     * @beta
     */
    const getUserMedia = async ({
        audio,
        video,
    }: MediaDeviceRequest): Promise<MediaStream> => {
        try {
            const stream = await getMediaStream({
                audio,
                video,
                getDefaultConstraints,
            });

            setupMediaStreamTracks({stream});
            return stream;
        } catch (error) {
            if (error instanceof Error) {
                dispatch({type: MediaEventType.Error, error});
            }
            throw error;
        }
    };

    return {getUserMedia};
};

/**
 * Create a MediaStreamTrack's native events subscription
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack#events
 *
 * @param track - The track used for the subscription
 * @param handlers - An object contains the event handlers
 */
export const createStreamTrackEventSubscriptions = (
    track: MediaStreamTrack,
    handlers: StreamTrackEventHandlers,
): Unsubscribe => {
    const trackSubscriptions = Object.keys(handlers).flatMap(eventKey => {
        const key = eventKey as keyof StreamTrackEventHandlers;
        const trackEventHandler = handlers[key];
        if (trackEventHandler) {
            const handleEvent = () => trackEventHandler(track);
            track.addEventListener(key, handleEvent);
            const removeTrackEventHandler = () => {
                track.removeEventListener(key, handleEvent);
            };
            return [removeTrackEventHandler];
        }
        return [];
    });
    return () => {
        for (const unsubscribe of trackSubscriptions) {
            unsubscribe();
        }
    };
};
