import '@pexip/components/src/fonts.css';
import '@pexip/components/dist/components.css';
import '@pexip/media-components/dist/media-components.css';

import React from 'react';
import {createRoot} from 'react-dom/client';

import './polyfills';
import './i18n';
import {App} from './App';

// getElementById can be potentially null, but not worth handling, too broken if it is
const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
