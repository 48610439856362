import React, {useCallback, useEffect, useRef, useState} from 'react';

import {announceSignal, type AnnounceSignal} from './AnnouncerSignal';

import styles from './Announcer.module.scss';

/**
 * Announcer component that announces messages to screen readers via aria-live region.
 * This component should be placed at the top of the application.
 * @returns The announcer component.
 */
export const Announcer: React.FC<{testId?: string}> = ({
    testId = '',
    ...props
}) => {
    const [assertiveAnnouncements, setAssertiveAnnouncements] = useState<
        AnnounceSignal[]
    >([]);
    const [politeAnnouncements, setPoliteAnnouncements] = useState<
        AnnounceSignal[]
    >([]);

    const timerIds = useRef<NodeJS.Timeout[]>([]);

    const addAnnouncement = useCallback(
        (
            event: AnnounceSignal,
            setAnnouncements: React.Dispatch<
                React.SetStateAction<AnnounceSignal[]>
            >,
        ) => {
            setAnnouncements(prevAnnouncements => [
                ...prevAnnouncements,
                event,
            ]);
            const timeoutId = setTimeout(() => {
                setAnnouncements(prevAnnouncements =>
                    prevAnnouncements.filter(
                        announcement => announcement !== event,
                    ),
                );
                clearTimeout(timeoutId);
                timerIds.current = timerIds.current.filter(
                    id => id !== timeoutId,
                );
            }, event.timeout);
            timerIds.current.push(timeoutId);
        },
        [],
    );

    const handleAnnouncement = useCallback(
        (event: AnnounceSignal) => {
            if (event.politeness === 'assertive') {
                addAnnouncement(event, setAssertiveAnnouncements);
            } else if (event.politeness === 'polite') {
                addAnnouncement(event, setPoliteAnnouncements);
            }
        },
        [addAnnouncement],
    );

    useEffect(() => {
        announceSignal.add(handleAnnouncement);
        return () => {
            announceSignal.remove(handleAnnouncement);
            for (const timerId of timerIds.current) {
                clearTimeout(timerId);
            }
            timerIds.current = [];
        };
    }, [handleAnnouncement]);

    return (
        <div {...props} className={styles.announcer} data-testid={testId}>
            <div
                data-testid={`${testId}-polite`}
                aria-live="polite"
                role="log"
                aria-relevant="additions"
            >
                {politeAnnouncements.map((announcement, index) => (
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation> FIXME: NEEDS UNIQUE ID
                    <div key={index}>{announcement.message}</div>
                ))}
            </div>
            <div
                data-testid={`${testId}-assertive`}
                aria-live="assertive"
                role="log"
                aria-relevant="additions"
            >
                {assertiveAnnouncements.map((announcement, index) => (
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation> FIXME: The announce needs something unique
                    <div key={index}>{announcement.message}</div>
                ))}
            </div>
        </div>
    );
};

/**
 * Announce a message to screen readers.
 * Announcer needs to be mounted in the application for this to work.
 * @param message - The message to announce.
 * @param politeness - The politeness level of the announcement.
 * @param timeout - The time in milliseconds util the message is removed from area-live region.
 */
export const announce = (
    message: React.ReactNode | string,
    politeness: 'polite' | 'assertive' = 'polite',
    timeout = 5000,
): void => {
    announceSignal.emit({message, politeness, timeout});
};
