import {useEffect, useState, useCallback, useMemo} from 'react';

declare global {
    interface Window {
        /** Used to force test different media queries in Storybook. */
        __STORYBOOK_FORCE_MEDIA_QUERY__?: string;
    }
}

// Checks the Window object for forcedMediaQuery.
const getForceMatchMedia = (mediaQuery: string) =>
    '__STORYBOOK_FORCE_MEDIA_QUERY__' in window &&
    window.__STORYBOOK_FORCE_MEDIA_QUERY__
        ? mediaQuery.includes(window.__STORYBOOK_FORCE_MEDIA_QUERY__)
        : undefined;

export const useMatchMedia = (mediaQuery: string) => {
    const hasForcedMql = getForceMatchMedia(mediaQuery);

    const mql = useMemo(() => {
        if ('matchMedia' in window) {
            return window.matchMedia(mediaQuery);
        }
    }, [mediaQuery]);
    const [matches, setMatches] = useState(mql?.matches ?? false);

    const handleQueryChange = useCallback(
        (e: MediaQueryListEvent) => setMatches(e.matches),
        [],
    );

    useEffect(() => {
        mql?.addEventListener('change', handleQueryChange);

        return () => {
            mql?.removeEventListener('change', handleQueryChange);
        };
    }, [mql, handleQueryChange]);

    return hasForcedMql ?? matches;
};
