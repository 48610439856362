import React, {useEffect, useRef, useState} from 'react';

import {Toast} from '../Toast/Toast';
import type {ToastProps} from '../Toast/Toast';

import {notificationToastSignal} from './NotificationToastSignal';
import type {NotificationToastMessage} from './NotificationToastSignal';

interface NotificationToastMessageWithID extends NotificationToastMessage {
    id: number;
}

const defaultToastTimeout = 3000;
const cleanupTimeoutOffset = 400;
const defaultToastCleanupTimeout = defaultToastTimeout + cleanupTimeoutOffset;

let toastID = 0;

const sameToast = (
    a?: NotificationToastMessageWithID,
    b?: NotificationToastMessageWithID,
) => {
    if (!a || !b) {
        return false;
    }
    if (a.colorScheme !== b.colorScheme) {
        return false;
    }

    if (a.message !== b.message) {
        return false;
    }
    return true;
};

/**
 * The NotificationToast component works like a normal toast, except it
 * displays data received via a signal, and it stores the messages as an array,
 * displaying one at a time.
 *
 * Consumers can add a new message by doing:
 *
 * ```js
 *     notificationToast.emit([{ message: 'My message' }]);
 * ```
 */
export const NotificationToast: React.FC<Partial<ToastProps>> = toastProps => {
    const timeoutIdRef = useRef<number>();
    const [toasts, setToasts] = useState<NotificationToastMessageWithID[]>([]);
    const [toast] = toasts;
    const toastTimeout = toast?.timeout ?? defaultToastTimeout;

    useEffect(
        () =>
            notificationToastSignal.add(data => {
                if (Array.isArray(data)) {
                    const toastsWithIDs = data.map(toast => ({
                        ...toast,
                        id: toastID++,
                    }));
                    setToasts(state => {
                        const interrupters = toastsWithIDs.filter(
                            toast => toast.isInterrupt,
                        );
                        if (interrupters.length > 0) {
                            const nonInterrupters = toastsWithIDs.filter(
                                toast => !toast.isInterrupt,
                            );

                            /**
                             * The toast(s) that interrupt will replace the currently rendering toast,
                             * therefore we only want to keep the rest of the toasts that come after it.
                             */
                            const [first, ...rest] = state;

                            /**
                             * A 0 timeout toast cannot be interrupted, toasts that
                             * try to interrupt a 0 timeout toast are discarded.
                             */
                            if (first?.timeout === 0) {
                                return [...state, ...nonInterrupters];
                            }

                            clearTimeout(timeoutIdRef.current);
                            timeoutIdRef.current = undefined;
                            return [
                                ...interrupters,
                                ...rest,
                                ...nonInterrupters,
                            ];
                        }
                        return state.concat(toastsWithIDs);
                    });
                } else if (data.close) {
                    setToasts(state => {
                        const [first, ...rest] = state;
                        if (first?.timeout === 0) {
                            // We want the close animation to take place so we set a low
                            // timeout on the toast instead of removing it from toasts.
                            return [{...first, timeout: 1}, ...rest];
                        }
                        return state;
                    });
                }
            }),
        [],
    );

    /**
     * Every time the number of toast messages change we check if we need to
     * start a timer to remove a toast message. The same check is done every
     * time the previous timer is done.
     */
    useEffect(() => {
        const toastCleanupTimeout = toast?.timeout
            ? toast.timeout + cleanupTimeoutOffset
            : defaultToastCleanupTimeout;
        const isHalted = toastTimeout === 0;
        const shouldStartTimer =
            toasts.length > 0 && timeoutIdRef.current == null && !isHalted;

        if (shouldStartTimer) {
            const shiftMessages = () => {
                setToasts(prev => {
                    const [first] = prev;
                    let toasts = prev.slice(1);
                    // Remove duplicate messages:
                    while (toasts[0] && sameToast(first, toasts[0])) {
                        toasts = toasts.slice(1);
                    }
                    return toasts;
                });
                timeoutIdRef.current = undefined;
            };

            timeoutIdRef.current = window.setTimeout(
                shiftMessages,
                toastCleanupTimeout,
            );
        }
    }, [toasts, toast, toastTimeout]);

    if (toast) {
        const colorScheme = toast.colorScheme ?? 'light';

        return (
            <Toast
                {...toastProps}
                colorScheme={colorScheme}
                enhanceStart={toast.enhanceStart}
                isDisplayed
                isDanger={toast.isDanger}
                isClickable={toast.isClickable}
                isVisible
                // Because we are re-using a component with state we pass along
                // "key" to let React understand that it should recreate the
                // element (with new state) when we pass along a new message.
                key={toast.id}
                message={toast.message}
                position={toast.position}
                timeout={toastTimeout}
                data-testid={toast.testid}
                onDismiss={toast.onDismiss}
            />
        );
    }

    return null;
};
