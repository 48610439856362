import React from 'react';
import cx from 'classnames';

import type {ImageSrc} from '../../../types/images';
import {useLogo} from '../../../hooks/useLogo';

import styles from './Logo.module.scss';

export const Logo: React.FC<
    React.ComponentProps<'img'> & {
        imageSrc: ImageSrc;
        alt: string;
        className?: string;
    }
> = ({imageSrc, alt, className, ...props}) => {
    const {logoSrcString, logoSrcSet, isLogoSrcSet} = useLogo(imageSrc);

    if (!logoSrcString && !logoSrcSet) {
        return null;
    }

    return (
        <img
            srcSet={isLogoSrcSet ? logoSrcSet : logoSrcString}
            className={cx(className, styles.logo)}
            {...props}
            alt={alt}
        />
    );
};

export type LogoProps = React.ComponentProps<typeof Logo>;
