import {useCallback, useEffect, useState} from 'react';

import {throttle} from '@pexip/utils';
import {createSignal} from '@pexip/signal';

export const UI_INACTIVITY_TIMEOUT = 5000;

export type AutoHideUIInterfacesState = 'visible' | 'hidden';

export const autoHideUIInterfacesStateSignal =
    createSignal<AutoHideUIInterfacesState>({
        name: 'autoHideUIInterfaces:state',
    });

/**
 * Hook to signal display state (show/hide) based on UI inactivity timeout
 * @param isStreamReady - only apply autoHide if we've got a ready stream
 * @param isDisabledByConfig - if true then auto hiding is disabled, UI controls will not be hidden
 * @param shouldDisplay - callback to signal when to hide and when to show
 * @returns control to enable/disable auto hide functionality
 */
export const useAutoHideUIInterfaces = (
    isStreamReady: boolean,
    isDisabledByConfig: boolean,
    shouldDisplay?: (shouldDisplay: boolean) => void,
) => {
    const [enabled, setEnabled] = useState(true);

    const enableAutoHideConstraintBased = useCallback((constraint: boolean) => {
        if (!constraint) {
            setEnabled(true);
        }
    }, []);

    useEffect(() => {
        let ignore = false;
        let timeout: ReturnType<typeof setTimeout>;

        const timeoutHandler = () => {
            shouldDisplay?.(false);
            document.body.dataset.uiHidden = '';
            autoHideUIInterfacesStateSignal.emit('hidden');
            document.addEventListener('keydown', tabKeydown, {once: true});
        };

        const onMouseMoveCallback = () => {
            if (ignore) {
                return;
            }

            clearTimeout(timeout);

            shouldDisplay?.(true);
            delete document.body.dataset.uiHidden;
            autoHideUIInterfacesStateSignal.emit('visible');

            timeout = setTimeout(timeoutHandler, UI_INACTIVITY_TIMEOUT);
        };

        const throttled = throttle(onMouseMoveCallback, 500);

        const tabKeydown = (e: KeyboardEvent) => {
            if (e.code === 'Tab') {
                onMouseMoveCallback();
            }
        };

        if (!isDisabledByConfig && isStreamReady && enabled) {
            timeout = setTimeout(timeoutHandler, UI_INACTIVITY_TIMEOUT);
            document.addEventListener('mousemove', throttled);
        }

        return () => {
            ignore = true;
            delete document.body.dataset.uiHidden;
            autoHideUIInterfacesStateSignal.emit('visible');
            clearTimeout(timeout);
            document.removeEventListener('keydown', tabKeydown);
            document.removeEventListener('mousemove', throttled);
        };
    }, [shouldDisplay, enabled, isStreamReady, isDisabledByConfig]);

    return {enableAutoHide: setEnabled, enableAutoHideConstraintBased};
};
