import type {Buffer} from './types';

export const createBuffer = <T>(size: number): Buffer<T> => {
    let buffer = new Array<T>();

    return {
        [Symbol.iterator]: () => buffer[Symbol.iterator](),
        add: value => {
            if (size === 0) {
                return [];
            }
            if (buffer.length < size) {
                buffer.splice(size, 0, value);
            } else {
                buffer = [...buffer.slice(1), value];
            }
        },
        entries: () => buffer.entries(),
    };
};
