/**
 * A token type to describe URL parsed result
 * it can only a a general pattern or an Url Parameter
 *
 * ```
 * /foo/:bar
 *  \_/ \_/
 *   |   |
 *   |   Param
 *   |
 * Pattern
 * ```
 */
export enum TokenType {
    /**
     * A general pattern token
     */
    Pattern = 'PATTERN',
    /**
     * An Url parameter token
     */
    Param = 'PARAM',
}

/**
 * An interface for parsed token object
 */
interface Token {
    type: TokenType;
    value: string;
}

interface ParseOptions {
    urlParamPattern?: string;
    pathDeliminator?: string;
    paramDeliminator?: string;
    groupDeliminatorStart?: string;
    groupDeliminatorEnd?: string;
}

const {Pattern, Param} = TokenType;

/**
 * Option for tokensToRegexPattern
 */
export interface TokensToRegexOption {
    /**
     * The deliminator which will be used for joining the result string
     */
    deliminator?: string;
    /**
     * Encoder to encode the token value before we join them
     * This will be useful when we need to encode the string to form a valid URL
     * string, e.g. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURI
     */
    encode?: (value: string) => string;
}

/**
 * A function to translate tokens into Regex for Url matching
 *
 * @param tokens - List of parsed tokens
 * @param options - {@link TokensToRegexOption}
 *
 * @returns A string representation of the regex
 */
export const tokensToRegexPattern = (
    tokens: Token[],
    options: TokensToRegexOption = {},
) => {
    const {deliminator = '/', encode = (x: string) => x} = options;
    const segments = tokens.map(token =>
        token.type === Pattern ? encode(token.value) : '([^/]+)',
    );
    return ['^', ...segments].join(deliminator);
};

/**
 * @internal
 */
const createPatternToken = (value: string) => ({
    type: Pattern,
    value,
});

/**
 * @internal
 */
const createParamToken = (value: string) => ({
    type: Param,
    value,
});

/**
 * A parser to parse provided path and turn it into a list of tokens
 *
 * @param path - Path for the route
 * @param options - See {@link ParseOptions}
 *
 * @returns A list of {@link Token}
 */
export const parse = (path: string, options: ParseOptions = {}): Token[] => {
    const {
        pathDeliminator = '/',
        paramDeliminator = ':',
        groupDeliminatorStart = '(',
        groupDeliminatorEnd = ')',
        urlParamPattern = '[a-zA-Z][a-zA-Z0-9_]*',
    } = options;
    const pathSegments = path.slice(1).split(pathDeliminator);
    const urlParamRegex = new RegExp(
        ['^', paramDeliminator, urlParamPattern, '$'].join(''),
    );

    return pathSegments.map(segment => {
        const hasGroup = segment.includes(groupDeliminatorStart);
        const hasParam = segment.includes(paramDeliminator);
        if (hasParam && !urlParamRegex.test(segment)) {
            throw new Error('Invalid URL Parameters defined');
        }
        if (hasGroup && ['?', '\\'].some(p => segment.includes(p))) {
            throw new Error('Invalid Group URL defined');
        }
        const pattern = hasGroup
            ? segment
                  .replace(groupDeliminatorStart, '(?:')
                  .replace(groupDeliminatorEnd, ')')
            : segment;

        return hasParam
            ? createParamToken(segment.slice(1))
            : createPatternToken(pattern);
    });
};

/**
 * Option to control the parsing result
 */
export interface PathMatchOption {
    /**
     * Should perform an exact match or not
     */
    exact?: boolean;
}

// FIXME: Type it better
export type HistoryState<T = string | boolean | number> = Record<string, T>;

/**
 * Url Interface
 *
 * ```
 * https://example.com:8042/over/there?name=ferret#nose
 * \___/   \_________/\___/\_________/ \_________/ \__/
 *   |          |       |      |            |        |
 * protocol   host    port   path        query   fragment
 * ```
 */
export interface Url {
    /**
     * The protocol scheme, e.g. "https"
     */
    protocol: string;
    /**
     * Host name, e.g. "example.com"
     */
    host: string;
    /**
     * Port number, e.g. 8042
     */
    port?: number;
    /**
     * Url path, e.g. "/over/there"
     */
    path: string;
    /**
     * Search query, e.g. "name=ferret"
     */
    query: string;
    /**
     * Hash fragment, e.g. "nose"
     */
    fragment: string;

    /**
     * History state
     */
    state: HistoryState;

    /**
     * Combine protocol, host and port to form Location.origin
     */
    toOrigin(): string;

    /**
     * Check if the Url is internal Url
     *
     * @returns `true` if it is an internal URL with regard to
     * `location.origin`
     */
    isInternal(): boolean;

    /**
     * Check if the Url is internal Url
     *
     * @returns `true` if it is an external URL with regard to `location.origin`
     */
    isExternal(): boolean;

    /**
     * Get full href string
     *
     * @returns Full Href as of URL.href
     */
    toString(): string;

    /**
     * Get stripped version of href without the `protocol`, `host` and `port`
     *
     * @returns an internal path
     */
    toInternalHref(): string;
}

/**
 * Matched Url Parameters
 */
export type Params = Record<string, string>;

/**
 * Match result from createPathMatch
 */
export interface Match<T extends Params = Params> {
    /**
     * {@inheritDoc Params}
     */
    params: T;
    /**
     * A boolean to indicate whether match or not
     */
    matched: boolean;
    /**
     * Predefined route path
     */
    path: string;
    /**
     * The actual Url to match with `path`
     */
    pathname: string;
}

/**
 * Interface for injected Match Props
 */
export interface RouteMatch<T extends Params = Params> {
    /**
     * Indicate if the route is exact matched
     */
    exact: boolean;
    /**
     * Matched Url Parameter
     */
    params: T;
    /**
     * The path defined for the route
     */
    path: string;
    /**
     * The url
     */
    url: Url;
    /**
     * A flag to indicate that the route is used for fallback
     */
    fallback: boolean;
}

/**
 * Create a path matcher for provided `pathname`
 *
 * @param pathname - The pathname from `location.pathname`
 *
 * @returns A path matcher to match predefined route path
 *
 * @example
 *
 * ```typescript
 * const matchPath = createPathMatch('/foo/1234');
 * const {matched, params, path, pathname} = matchPath('/foo/:bar');
 *
 * expect(matched).toBeTruthy();
 * expect(params).toEqual({bar: '1234'});
 * expect(path).toEqual('/foo/:bar');
 * expect(pathname).toEqual('/foo/1234');
 * ```
 */
export const createPathMatch =
    <T extends Params = Params>(pathname: string) =>
    (path: string, options: PathMatchOption = {}): Match => {
        const {exact = false} = options;
        const tokens = parse(path);
        const paramTokens = tokens.filter(token => token.type === Param);
        const pathPattern = [
            tokensToRegexPattern(tokens),
            exact ? '$' : '',
        ].join('');
        const pathRegex = new RegExp(pathPattern);

        const result = pathRegex.exec(pathname);
        const params =
            paramTokens.length && result?.length
                ? paramTokens.reduce((params, token, idx) => {
                      const param = result[idx + 1];
                      return {
                          ...params,
                          [token.value]: param
                              ? decodeURIComponent(param)
                              : param,
                      };
                  }, {} as T)
                : {};
        const matched = Boolean(result);

        return {params, matched, path: pathPattern, pathname};
    };

/**
 * Utility function to remove duplicated slashes
 *
 * @param path - the path to be used for the removing
 */
export const dedupSlash = (path: string) => path.replace(/\/{2,}/, '/');

/**
 * Utility function to remove trailing slash if it is not at the beginning
 *
 * @param path - the path to be used for the removing
 */
export const deTrailingSlash = (path: string) => path.replace(/(.+)\/$/, '$1');

/**
 * Utility function to join provided `path` with the `base`
 *
 * @param path - the path to join with base
 * @param base - base path
 */
export const joinPath = (path: string, base: string): string =>
    deTrailingSlash(dedupSlash([base, path].join('/')));

export interface BuildUrlOptions {
    state?: Url['state'];
    title?: string;
    base?: string;
    location?: Location;
}

/**
 * Url builder
 *
 * @param href - A link ar a path
 * @param options - @see BuildUrlOptions
 * @returns Url {@link Url}
 */
export const buildUrl = (
    href: string,
    {
        state,
        title = document.title,
        base = '',
        location = window.location,
    }: BuildUrlOptions = {},
): Url => {
    // Build a URL relative to the current location.href
    const url = new URL(href, location.href);

    if (base) {
        url.pathname = joinPath(url.pathname, base);
    }

    const urlProps = {
        // Remove ":" from URL.protocol
        protocol: url.protocol.slice(0, -1),
        host: url.hostname,
        // Convert the port into number if there is port
        port: url.port ? Number.parseInt(url.port, 10) : undefined,
        path: url.pathname,
        // Remove "?" from URL.search
        query: url.search.slice(1),
        // Remove "#" from URL.hash
        fragment: url.hash.slice(1),
        state: {title, ...state} as Url['state'],
    };

    const port = urlProps.port ? `:${urlProps.port}` : '';

    const toOrigin = () => `${urlProps.protocol}://${urlProps.host}${port}`;

    const isInternal = () => location.origin === toOrigin();

    const isExternal = () => !isInternal();

    const toString = () => url.href;

    const query = urlProps.query ? `?${urlProps.query}` : urlProps.query;

    const fragment = urlProps.fragment
        ? `#${urlProps.fragment}`
        : urlProps.fragment;

    const toInternalHref = () => [urlProps.path, query, fragment].join('');

    return {
        ...urlProps,
        toOrigin,
        isInternal,
        isExternal,
        toString,
        toInternalHref,
    };
};

export const isUrl = (url: unknown): url is Url =>
    !!(typeof url === 'object' && url && 'host' in url);

export const toUrl = (url: Url | string, options?: BuildUrlOptions) =>
    isUrl(url) ? url : buildUrl(url, options);

/**
 * Check if the provided url is internal
 *
 * @param url - Url to check w/r/t `location.origin`
 *
 * @returns `true` if it is internal url
 */
export const isInternalUrl = (url: Url) => {
    return toUrl(url).isInternal();
};
