import React from 'react';

import type {ExtendedFixedSizeModifier} from '../../../types/sizes';
import type {ColorScheme} from '../../../types/variants';
import {getInitials} from '../../../utils/string';
import {IconTypes} from '../../elements/Icon/Icon';
import type {
    RoundIndicatorBaseProps,
    RoundIndicatorBaseTypeProps,
    RoundIndicatorModifier,
} from '../RoundIndicator/RoundIndicator';
import {RoundIndicator} from '../RoundIndicator/RoundIndicator';

import {AvatarType} from './Avatar.types';
import {getAvatarFontVariant} from './Avatar.utils';
import {MAX_NUMBER, useAvatarNumber} from './useAvatarNumber';

export const Avatar: React.FC<
    RoundIndicatorBaseProps & {
        colorScheme?: ColorScheme;
        displayName?: string;
        isActive?: boolean;
        modifier?: RoundIndicatorModifier;
        number?: number;
        sizeModifier?: ExtendedFixedSizeModifier;
        type: AvatarType;
    }
> = ({
    alt,
    colorScheme,
    displayName,
    iconType,
    imageUrl,
    isActive,
    number = 0,
    sizeModifier = 'small',
    title,
    type: initialType,
    ...props
}) => {
    const isImage = initialType === AvatarType.Image && imageUrl;
    const isInitials = initialType === AvatarType.Initials;
    const isNumber =
        initialType === AvatarType.Number &&
        !Number.isNaN(number) &&
        number !== undefined;

    const {isMoreThanMaxNumber, fontVariant: numberFontVariant} =
        useAvatarNumber(number);

    // Logical fallback for the content of the Avatar
    const roundIndicatorProperties = (): RoundIndicatorBaseTypeProps => {
        if (isNumber) {
            return {
                type: 'text',
                text: isMoreThanMaxNumber ? `${MAX_NUMBER}+` : `+${number}`,
            };
        }

        if (isImage) {
            return {
                type: 'image',
                imageUrl,
                alt: alt ?? '',
            };
        }

        if (displayName !== undefined && (isInitials || !isImage)) {
            return {
                type: 'text',
                text:
                    displayName !== '' ? getInitials(displayName) : displayName,
            };
        }

        return {
            type: 'icon',
            iconType: iconType ?? IconTypes.IconMoreHorizontal,
            title: title ?? displayName ?? 'User avatar',
        };
    };

    return (
        <RoundIndicator
            colorScheme={colorScheme}
            fontVariant={
                isNumber
                    ? numberFontVariant
                    : getAvatarFontVariant(sizeModifier)
            }
            sizeModifier={sizeModifier}
            {...(isActive
                ? {'data-user-avatar-active': true}
                : {'data-user-avatar': true})}
            {...props}
            {...roundIndicatorProperties()}
        />
    );
};
